.Container{
  text-align: center;
  font-family: 'Quicksand', sans-serif;
  padding-right: 30px;
  padding-bottom: 25px;
  padding-left: 30px;
}
.hoursContainer{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
body {
  background-color: #ffff;
}

p{
  font-size: 25px;

}

h1{
  font-size: 50px;
  font-family: 'Quicksand', sans-serif;


}

.logo{
  padding: 20px;
  border-radius: 25%;  
}

.image{
  padding: 20px;
  border-radius:20%;
}

.backg{
  object-fit: cover;
  width: 50vh;
  height:auto;

}

.row::after {
  display: flex;
  clear: both;
  display: table;

}


